
import React from 'react';

export default function PaymentRefund() {

  return (
    <React.Fragment>
            <h2>Reembolsar Pago</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api/payment/capture
              </code>
            </pre>
            <p>
              Gestiona el reembolso del pago de una orden de TastyBoom a traves de la pasarela de pago seleccionada.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando los siguientes parametros:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    origin: "webpay",
    order_id: 45896,
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>origin</td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Codigo unico asociado a la pasarela de pago en TastyBoom.</td>
                </tr>
                <tr>
                  <td>order_id</td>
                  <td>Integer</td>
                  <td>Yes</td>
                  <td>Order number in TastyBoom.
                  <br/>Examples: 45896</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
   "authorization_code":null,
   "authorization_date":null,
   "balance":null,
   "nullified_amount":null,
   "response_code":null,
   "type":"REVERSED"
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <p>
              Objeto retornado dependera de la data retornada por cada pasarela de pago externa.
            </p>
    </React.Fragment>
  );
}
