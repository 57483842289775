
import React from 'react';

export default function DeleteToken() {

  return (
    <React.Fragment>
            <h2>Remover token de tarjeta</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api/payment/capture
              </code>
            </pre>
            <p>
              Elimina el token registrado para la tarjeta de pago de un cliente en TastyBoom. 
              Aplica actualmente solo para tarjetas asociadas a pagos con Transbank-Oneclick.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando los siguientes parametros:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    token: d60ff2f4b18c9a40fce2bda1116e,
    user: 45866996,
    origin: "webpay",
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>token</td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Token asociado a la tarjeta del cliente.</td>
                </tr>
                <tr>
                  <td>user</td>
                  <td>Integer</td>
                  <td>Yes</td>
                  <td>Identificador del cliente registrado en TastyBoom.</td>
                </tr>
                <tr>
                  <td>origin</td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Codigo unico asociado a la pasarela de pago en TastyBoom.</td>
                </tr>
              </tbody>
            </table>
            <br />
    </React.Fragment>
  );
}
