
import React from 'react';

export default function PaymentCapture() {

  return (
    <React.Fragment>
            <h2>Capturar Pago</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api/payment/capture
              </code>
            </pre>
            <p>
              Gestiona el pago de una orden de TastyBoom a traves de la pasarela de pago seleccionada.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando los siguientes parametros:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    origin: "webpay",
    token_ws: CAd28d6d5f0d374a9da35c4da9,
    order_id: 45896,
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>origin</td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Codigo unico asociado a la pasarela de pago en TastyBoom.</td>
                </tr>
                <tr>
                  <td>order_id</td>
                  <td>Integer</td>
                  <td>Si, si el valor de token_ws es null.</td>
                  <td>Order number in TastyBoom.
                  <br/>Examples: 45896</td>
                </tr>
                <tr>
                  <td>token_ws</td>
                  <td>String</td>
                  <td>Si, si el valor de order_id es null.</td>
                  <td>Token devuelto por la plataforma externa de pago.</td>
                </tr>
                <tr>
                  <td>return_url</td>
                  <td>String</td>
                  <td>Si, si el valor de order_id no es null. Aplica solo para las pasarelas de pago de Transbank.</td>
                  <td>URL para redireccionar en caso de error en la plataforma externa de pago.</td>
                </tr>
                <tr>
                  <td>final_url</td>
                  <td>String</td>
                  <td>Si, si el valor de order_id no es null. Aplica solo para las pasarelas de pago de Transbank.</td>
                  <td>URL para redireccionar al finalizar el pago en la plataforma externa.</td>
                </tr>
                <tr>
                  <td>restoId</td>
                  <td>Integer</td>
                  <td>Si, si el valor de order_id es null. Aplica solo para pagos de Transbank-Webpay</td>
                  <td>Identificador del restaurante en TastyBoom.</td>
                </tr>
                <tr>
                  <td>branchId</td>
                  <td>Integer</td>
                  <td>Si, si el valor de order_id es null. Aplica solo para pagos de Transbank-Webpay</td>
                  <td>Identificador de la sucursal del restaurante en TastyBoom.</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
   "response":"1",
   "responseMessage":"APPROVAL",
   "noticeMessage":"New Card Stored",
   "date":"2021-09-29",
   "time":"17:51:53",
   "type":"verify",
   "amount":"0",
   "cardHolderName":"Julio",
   "cardNumber":"5500 **** **** 5010",
   "cardExpiry":"0124",
   "cardToken":"c528ce9173d992b5f9972",
   "cardType":"Mastercard",
   "transactionId":"6681233",
   "avsResponse":"X",
   "cvvResponse":"M",
   "approvalCode":"9745151J",
   "customerCode":"53CST25408",
   "currency":"CAD",
   "xmlHash":"d60ff2f4b18c9a40fce2bda1116e",
   "cardHolderAddress":"5300, 15e avenue",
   "cardHolderPostalCode":"H1X9G2"
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <p>
              Objeto retornado dependera de la data retornada por cada pasarela de pago externa.
            </p>
    </React.Fragment>
  );
}
