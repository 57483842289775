
import React from 'react';

export default function Credentials() {

  return (
    <React.Fragment>
            <h2>Credenciales de la pasarela de pago</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api/credentials/{`{type}`}
              </code>
            </pre>
            <p>
              Obtiene las credenciales de la pasarela de pago asociadas a la sucursal de un restaurante. 
              Aplicable actualmente solo para el servicio de Helcim.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando en la url el 
              identificador de la pasarela de pago que se desea consultar.<br />
            </p>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
[
 {
  "code_gateway":"helcim",
  "test":true,
  "accountId":"123456789",
  "apiToken":"eyJ0eXAiOiJKV1QiLCJey",
  "apiTokenJs":"eyJ0eXAiOiJKV1QiLCJey",
  "is_active":true
 }
]
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>code_gateway</td>
                  <td>Codigo unico asociado a la pasarela de pago en TastyBoom.</td>
                </tr>
                <tr>
                  <td>test</td>
                  <td>Se establece este campo Verdadero si el servicio esta configurado como conexion de PRUEBA.</td>
                </tr>
                <tr>
                  <td>accountId</td>
                  <td>Identificador de la cuenta en Helcim.</td>
                </tr>
                <tr>
                  <td>apiToken</td>
                  <td>Token asignado por la api de Helcim.</td>
                </tr>
                <tr>
                  <td>apiTokenJs</td>
                  <td>Token asignado por la api de HelcimJs.</td>
                </tr>
                <tr>
                  <td>is_active</td>
                  <td>Se establece este campo Verdadero si el servicio esta activo.</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
